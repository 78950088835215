import { template as template_2ea75442e423495da46e52f65aa6b946 } from "@ember/template-compiler";
const FKText = template_2ea75442e423495da46e52f65aa6b946(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
